<template>
  <div
    class="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed"
    style="background-image: url('media/illustrations/progress-hd.png')"
  >
    <!--begin::Content-->
    <div class="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20">
      <!--begin::Logo-->
      <!-- <a href="#" class="mb-12">
        <img alt="Logo" src="media/logos/logo-2-dark.svg" class="h-45px" />
      </a> -->
      <!--end::Logo-->

      <!--begin::Wrapper-->
      <div class="w-lg-500px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto">
        <!--begin::Form-->
        <form
          class="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
          id="kt_login_password_reset_form"
        >
          <!--begin::Heading-->
          <div class="text-center mb-10">
            <!--begin::Title-->
            <h1 class="text-dark mb-3">
              ลืมรหัสผ่าน ?
            </h1>
            <!--end::Title-->

            <!--begin::Link-->
            <div class="text-gray-400 fw-bold fs-4">
              กรอกข้อมูลอีเมลหรือเลขบัตรประชาชนของคุณ
              <br />เพื่อให้ระบบส่งลิงค์ลืมรหัสผ่าน
            </div>
            <!--end::Link-->
          </div>
          <!--begin::Heading-->

          <!--begin::Input group-->
          <!-- <div>
            <button
              type="button"
              :class="resetType == 1 ? 'btn btn-info' : 'btn'"
              @click="changeType(1)"
            >
              อีเมล
            </button>
            <button
              type="button"
              :class="resetType == 2 ? 'btn btn-info' : 'btn'"
              @click="changeType(2)"
            >
              เลขบัตรประชาชน
            </button>
          </div> -->
          <el-tabs
            v-model="activeName"
            class="demo-tabs"
            @tab-click="handleClick"
          >
            <el-tab-pane label="อีเมล" name="1">
              <div class="fv-row mb-10 p-5">
                <label class="form-label fw-bolder text-gray-900 fs-6"
                  >อีเมล</label
                >
                <input
                  class="form-control form-control-solid"
                  type="email"
                  placeholder=""
                  v-model="email"
                  autocomplete="off"
                />
                <div class="fv-plugins-message-container"></div>
              </div>
            </el-tab-pane>
            <el-tab-pane label="เลขบัตรประชาชน" name="2">
              <div class="fv-row mb-10 p-5">
                <label class="form-label fw-bolder text-gray-900 fs-6"
                  >เลขบัตรประชาชน</label
                >
                <input
                  class="form-control form-control-solid"
                  type="text"
                  placeholder=""
                  v-model="idCard"
                  maxlength="13"
                  @keypress="isNumber($event)"
                  @keyup="assertThaiId(idCard)"
                  autocomplete="off"
                />
                <div
                  v-if="idCard && idCard.length == 13 && checkIdcard == false"
                  class="text-danger"
                >
                  กรุณาระบุให้ถูกต้อง
                </div>
              </div>
            </el-tab-pane>
          </el-tabs>

          <!--end::Input group-->

          <!--begin::Actions-->
          <div class="d-flex flex-wrap justify-content-center pb-lg-0">
            <button
              type="button"
              @click="onSubmitForgotPassword"
              class="btn btn-lg btn-primary fw-bolder me-4"
            >
              <span class="indicator-label">
                ส่ง
              </span>
              <span class="indicator-progress">
                Please wait...
                <span
                  class="spinner-border spinner-border-sm align-middle ms-2"
                ></span>
              </span>
            </button>

            <router-link
              to="/sign-in"
              class="btn btn-lg btn-light-primary fw-bolder"
              >ยกเลิก</router-link
            >
          </div>
          <!--end::Actions-->
        </form>
        <!--end::Form-->
      </div>
      <!--end::Wrapper-->
    </div>
    <!--end::Content-->

    <!--begin::Footer-->
    <div class="d-flex flex-center flex-column-auto p-10">
      <!--begin::Links-->
      <!-- <div class="d-flex align-items-center fw-bold fs-6">
        <a href="#" class="text-muted text-hover-primary px-2">About</a>

        <a href="#" class="text-muted text-hover-primary px-2">Contact</a>

        <a href="#" class="text-muted text-hover-primary px-2">Contact Us</a>
      </div> -->
      <!--end::Links-->
    </div>
    <!--end::Footer-->
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import * as Yup from "yup";
import { Actions } from "@/store/enums/StoreEnums";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import axios from "axios";

const activeName = ref("1");
const resetType = ref(1);
const checkIdcard = ref(false);
const email = ref();
const idCard = ref();
export default defineComponent({
  name: "password-reset",
  components: {},
  setup() {
    const store = useStore();
    const router = useRouter();

    //Create form validation object
    const onSubmitForgotPassword = async () => {
      Swal.fire({
        title: "Loading",
        allowOutsideClick: false,
        onBeforeOpen: () => {
          Swal.showLoading();
        },
      });
      // console.log(email.value);
      const formData = new FormData();
      if (activeName.value == "1") {
        formData.append("email", email.value);
      } else {
        formData.append("idCard", idCard.value);
      }
      await axios
        .post(process.env.VUE_APP_API_URL + "/forgetPassword", formData)
        .then(async (response) => {
          // console.log(response.data);
          if (response.data.code == 200) {
            Swal.fire({
              text: response.data.message,
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "ตกลง",
              customClass: {
                confirmButton: "btn fw-semobold btn-light-primary",
              },
            }).then(function() {
              // console.log(router);
              router.push({ name: "home" });
            });
          } else {
            Swal.fire({
              text: response.data.message,
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "ลองใหม่ภายหลัง!",
              customClass: {
                confirmButton: "btn fw-semobold btn-light-danger",
              },
            });
          }
        })
        .catch((error) => {
          // console.log(error);
          Swal.fire({
            text: error,
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "ลองใหม่ภายหลัง!",
            customClass: {
              confirmButton: "btn fw-semobold btn-light-danger",
            },
          });
        });
    };

    onMounted(() => {
      store.dispatch(Actions.REMOVE_BODY_ATTRIBUTE, {
        qulifiedName: "style",
      });
    });

    store.dispatch(Actions.ADD_BODY_ATTRIBUTE, {
      qulifiedName: "style",
      value: "background-image: url(media/patterns/header-bg.jpg)",
    });
    const assertThaiId = (thaiId: string): boolean => {
      // console.log("assertThaiId : " + thaiId);
      if (thaiId.length == 13) {
        const m = thaiId.match(/(\d{12})(\d)/);
        if (!m) {
          console.warn("Bad input from user, invalid thaiId=", thaiId);
          throw new Error("thai-id-must-be-13-digits");
        }
        const digits = m[1].split("");
        const sum = digits.reduce((total: number, digit: string, i: number) => {
          return total + (13 - i) * +digit;
        }, 0);
        const lastDigit = `${(11 - (sum % 11)) % 10}`;
        const inputLastDigit = m[2];
        if (lastDigit !== inputLastDigit) {
          console.warn("Bad input from user, invalid checksum thaiId=", thaiId);
          // throw new Error("thai-id-checksum-mismatched");
          checkIdcard.value = false;
          return false;
        } else {
          checkIdcard.value = true;
          return true;
        }
      } else {
        return false;
      }
    };
    return {
      onSubmitForgotPassword,
      email,
      idCard,
      resetType,
      assertThaiId,
      checkIdcard,
      activeName
    };
  },
  methods: {
    // changeType(type) {
    //   if (type == 1) {
    //     idCard.value = "";
    //   } else {
    //     email.value = "";
    //   }
    //   resetType.value = type;
    // },
    isNumber: function(evt) {
      evt = evt ? evt : window.event;
      // eslint-disable-next-line prefer-const
      let charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
  },
});
</script>
<style>
.demo-tabs > .el-tabs__content {
  padding: 32px;
  color: #6b778c;
  font-size: 32px;
  font-weight: 600;
}
</style>
<!-- <script lang="ts" setup>
import { ref } from 'vue'
import type { TabsPaneContext } from 'element-plus'

const activeName = ref('first')

const handleClick = (tab: TabsPaneContext, event: Event) => {
  console.log(tab, event)
}
</script> -->
