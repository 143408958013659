
import { defineComponent, onMounted, ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import * as Yup from "yup";
import { Actions } from "@/store/enums/StoreEnums";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import axios from "axios";

const activeName = ref("1");
const resetType = ref(1);
const checkIdcard = ref(false);
const email = ref();
const idCard = ref();
export default defineComponent({
  name: "password-reset",
  components: {},
  setup() {
    const store = useStore();
    const router = useRouter();

    //Create form validation object
    const onSubmitForgotPassword = async () => {
      Swal.fire({
        title: "Loading",
        allowOutsideClick: false,
        onBeforeOpen: () => {
          Swal.showLoading();
        },
      });
      // console.log(email.value);
      const formData = new FormData();
      if (activeName.value == "1") {
        formData.append("email", email.value);
      } else {
        formData.append("idCard", idCard.value);
      }
      await axios
        .post(process.env.VUE_APP_API_URL + "/forgetPassword", formData)
        .then(async (response) => {
          // console.log(response.data);
          if (response.data.code == 200) {
            Swal.fire({
              text: response.data.message,
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "ตกลง",
              customClass: {
                confirmButton: "btn fw-semobold btn-light-primary",
              },
            }).then(function() {
              // console.log(router);
              router.push({ name: "home" });
            });
          } else {
            Swal.fire({
              text: response.data.message,
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "ลองใหม่ภายหลัง!",
              customClass: {
                confirmButton: "btn fw-semobold btn-light-danger",
              },
            });
          }
        })
        .catch((error) => {
          // console.log(error);
          Swal.fire({
            text: error,
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "ลองใหม่ภายหลัง!",
            customClass: {
              confirmButton: "btn fw-semobold btn-light-danger",
            },
          });
        });
    };

    onMounted(() => {
      store.dispatch(Actions.REMOVE_BODY_ATTRIBUTE, {
        qulifiedName: "style",
      });
    });

    store.dispatch(Actions.ADD_BODY_ATTRIBUTE, {
      qulifiedName: "style",
      value: "background-image: url(media/patterns/header-bg.jpg)",
    });
    const assertThaiId = (thaiId: string): boolean => {
      // console.log("assertThaiId : " + thaiId);
      if (thaiId.length == 13) {
        const m = thaiId.match(/(\d{12})(\d)/);
        if (!m) {
          console.warn("Bad input from user, invalid thaiId=", thaiId);
          throw new Error("thai-id-must-be-13-digits");
        }
        const digits = m[1].split("");
        const sum = digits.reduce((total: number, digit: string, i: number) => {
          return total + (13 - i) * +digit;
        }, 0);
        const lastDigit = `${(11 - (sum % 11)) % 10}`;
        const inputLastDigit = m[2];
        if (lastDigit !== inputLastDigit) {
          console.warn("Bad input from user, invalid checksum thaiId=", thaiId);
          // throw new Error("thai-id-checksum-mismatched");
          checkIdcard.value = false;
          return false;
        } else {
          checkIdcard.value = true;
          return true;
        }
      } else {
        return false;
      }
    };
    return {
      onSubmitForgotPassword,
      email,
      idCard,
      resetType,
      assertThaiId,
      checkIdcard,
      activeName
    };
  },
  methods: {
    // changeType(type) {
    //   if (type == 1) {
    //     idCard.value = "";
    //   } else {
    //     email.value = "";
    //   }
    //   resetType.value = type;
    // },
    isNumber: function(evt) {
      evt = evt ? evt : window.event;
      // eslint-disable-next-line prefer-const
      let charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
  },
});
